@import "../../styles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 25vw;

  @include xs {
    padding: 0 5vw;
  }

  min-height: 100dvh;
  position: relative;

  .beams {
    position: absolute;
    width: 100vw;
  }

  .logo {
    width: 50vw;
    max-width: 20rem;
    align-self: center;
    transform: translateX(5%);
  }

  .box {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
    border: 0.3rem solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s;
    backdrop-filter: blur(7px);
    padding-bottom: 1rem;

    .inner {
      width: 100%;
      height: calc(100% - 1rem);
      background-color: #fff;
      border-radius: 1.8rem 1.8rem 2.3rem 2.3rem;
      z-index: 2;
      padding: 3.2rem 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      h4,
      h5 {
        color: #000;
        z-index: 10;
        font-family: "Teko", sans-serif;
      }

      h1 {
        color: #e11244;
        z-index: 10;
        font-size: 12rem;
        line-height: 10rem;
        font-family: "Teko", sans-serif;
        font-weight: 900;

        span {
          color: #e11244;
          z-index: 10;
          font-size: 12rem;
          line-height: 10rem;
          font-family: "Teko", sans-serif;
          font-weight: 400;
          margin: 0 2px;
        }
      }

      h4 {
        font-size: 3rem;
        font-weight: 600;
      }

      h5 {
        font-size: 2.6rem;
        font-weight: 700;
      }
    }
  }

  .yourPicks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1.2rem;
    padding: 1rem 3rem;
    z-index: 10;
    backdrop-filter: blur(8px);
    margin-top: 5dvh;
    margin-bottom: 2rem;

    img {
      width: 4rem;
      height: 4rem;

      &.crown {
        width: 3rem;
        height: 3rem;
        margin: 0.5rem 0;
      }
    }

    h4 {
      font-weight: 500;
    }
  }
}

.boxLong {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  border: 0.3rem solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s;
  backdrop-filter: blur(7px);
  padding-bottom: 1rem;

  .inner {
    width: 100%;
    height: calc(100% - 1rem);
    background-color: #fff;
    border-radius: 1.8rem 1.8rem 2.3rem 2.3rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .row {
    flex-direction: row;
    overflow: hidden;

    .left {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding-left: 2rem;

      .rank {
        color: #7b7b7b;
        font-family: "Teko", sans-serif;
        font-weight: 900;
        font-size: 3rem;
        padding-top: 1.1rem;
        width: 4rem;
      }

      .name {
        color: #000;
        font-size: 3.4rem;
        padding: 1rem 0;
        font-weight: 600;
      }
    }

    .right {
      background-color: #e3e3e3;
      font-family: "Teko", sans-serif;
      color: #878787;
      height: 100%;
      font-size: 3.4rem;
      padding: 1.4rem 2rem;
    }
  }
}

.itemRow {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  padding: 2rem 3rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid #cacaca;
  }

  img {
    width: 2.6rem;
    height: 2.6rem;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    p {
      color: #000;
      font-weight: 400;
      font-size: 2rem;

      &:last-of-type {
        font-weight: 500;
      }
    }
  }
}

.leaderboard {
  width: 100%;
  margin: 2rem 0;
  max-width: 65rem;
}

.loading {
  margin-top: 4rem;
}

.rowNew {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  width: 100%;

  padding: 0 24%;

  @include lg {
    padding: 0 10%;
  }

  img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
  }

  .innerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    div {
      background-color: #e5e5e5;
      height: 0.4rem;
      width: 1.2rem;
    }

    input {
      font-size: 3rem;
      width: 6.5rem;
      height: 7rem;
      text-align: center;
      border-radius: 1.2rem;
      border: 2px solid #e5e5e5;

      transition: all 0.3s;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      -moz-appearance: textfield;
      appearance: textfield;

      &::placeholder {
        color: #c6c6c6;
      }

      &:disabled {
        background-color: #f5f5f5;
        color: black;
      }

      &:focus {
        outline: none !important;
        border: 2px solid #c6c6c6;
      }
    }
  }
}

.marginBottom {
  margin-bottom: 2rem;
}
