@import "./variables";
@import "./mixins";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 62.5%;

  @include md {
    font-size: 57.5%;
  }

  @include sm {
    font-size: 50%;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background: linear-gradient(rgb(255, 122, 138), rgb(250, 43, 82));
}

body {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-weight: 900;
  font-size: 4.4rem;
  line-height: 5rem;
  color: $white;

  @include sm {
    line-height: 4.2rem;
  }

  span {
    font-weight: 900;
    font-size: 4.4rem;
    line-height: 5rem;

    @include sm {
      line-height: 4.2rem;
    }
  }
}

h2 {
  font-weight: 900;
  font-size: 3.4rem;
  line-height: 4rem;
  color: $white;
}

h3 {
  font-weight: 500;
  font-size: 3rem;
  line-height: 4rem;
  color: $white;

  @include sm {
    line-height: 3.4rem;
  }
}

h4 {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: $white;
}

h5 {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: $white;

  span {
    font-weight: 500;
    line-height: 2.6rem;
  }
}

h6 {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $white;

  @include sm {
    line-height: 2rem;
  }
}

p,
span,
a {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: $white;
}

.padding {
  padding: 0 35vw;

  @include xlg {
    padding: 0 25vw;
  }
  @include sm {
    padding: 0 5vw;
  }
}
