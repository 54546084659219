@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100dvh;

  .started {
    height: 100%;
    font-size: 3rem;
    font-family: "Teko", sans-serif;
    display: grid;
    place-items: center;
  }

  .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
    z-index: 100;
    display: grid;
    place-items: center;

    .inner {
      width: 50vw;
      max-width: 50rem;
      padding-bottom: 0.5rem;
      background-color: #c4c4c4;
      border: 1px solid rgba(0, 0, 0, 0.25);
      z-index: 101;
      border-radius: 1rem;
      position: relative;

      @include sm {
        width: 90vw;
      }

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8rem;
        height: 8rem;
      }

      .content {
        height: calc(100% - 0.5rem);
        background-color: #fff;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 1.25rem;
        border-bottom-right-radius: 1.25rem;
        display: grid;
        place-items: center;
        padding: 6rem 2rem 4rem 2rem;

        h3 {
          color: #000;
          text-align: center;
          font-family: "Teko", sans-serif;
          font-weight: 500;
        }
      }
    }
  }

  .barContainer {
    width: 90vw;
    max-width: 50rem;
    align-self: center;
    position: relative;
    display: flex;
    align-items: center;

    .bar {
      width: 100%;
    }

    .barInner {
      position: absolute;
      height: 65%;
      background: linear-gradient(
        rgb(212, 255, 68) 0%,
        rgb(130, 201, 64) 60%,
        rgb(0, 115, 59) 100%
      );
      left: 1.5%;
      border-radius: 90vw;
      transition: all 0.4s;
    }

    .questionNumber {
      color: #fff;
      font-size: 3rem;
      position: absolute;
      text-align: center;
      width: 100%;
      font-weight: bolder;
      -webkit-text-stroke: 1.5px black;
      padding-bottom: 0.2rem;
    }
  }

  a,
  .logo {
    width: 50vw;
    max-width: 20rem;
    align-self: center;
    transform: translateX(5%);
  }

  .center {
    display: flex;
    flex: 1;
    position: relative;
  }
}

.loading {
  height: 100dvh;
  display: grid;
  place-items: center;
}
