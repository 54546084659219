@import "../../styles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 25vw;

  @include xs {
    padding: 0 5vw;
  }

  min-height: 100dvh;
  position: relative;

  h1 {
    font-size: 5.6rem;
    line-height: 5.6rem;
    -webkit-text-stroke: 4px black;
    z-index: 1;
  }

  h2 {
    font-size: 3rem;
    line-height: 3rem;
    -webkit-text-stroke: 2px black;
    z-index: 1;
    margin: 2rem;
  }

  .beams {
    position: absolute;
    width: 100vw;
  }

  .logo {
    width: 50vw;
    max-width: 20rem;
    align-self: center;
    transform: translateX(5%);
  }

  .crown {
    width: 15vw;
    height: 15vw;
    max-width: 8rem;
    max-height: 8rem;
    z-index: 1;
    margin-bottom: 2rem;

    @include md {
      margin-top: 8vw;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-bottom: 5dvh;
}

.boxLong {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  border: 0.3rem solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s;
  backdrop-filter: blur(7px);
  padding-bottom: 1rem;

  .inner {
    width: 100%;
    height: calc(100% - 1rem);
    background-color: #fff;
    border-radius: 1.8rem 1.8rem 2.3rem 2.3rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    flex-direction: row;
    overflow: hidden;

    .left {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding-left: 2rem;

      .rank {
        color: #7b7b7b;
        font-family: "Teko", sans-serif;
        font-weight: 900;
        font-size: 3rem;
        padding-top: 1.1rem;
        width: 4rem;
      }

      .name {
        color: #000;
        font-size: 3.4rem;
        padding: 1rem 0;
        font-weight: 600;
      }
    }

    .right {
      background-color: #e3e3e3;
      font-family: "Teko", sans-serif;
      color: #878787;
      height: 100%;
      font-size: 3.4rem;
      padding: 1.4rem 2rem;
    }
  }
}

.loading {
  margin-top: 4rem;
}
