@import "../../styles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 25vw;

  @include xs {
    padding: 0 5vw;
  }

  min-height: 100dvh;
  position: relative;

  .beams {
    position: absolute;
    width: 100vw;
  }

  .logo {
    width: 50vw;
    max-width: 20rem;
    align-self: center;
    transform: translateX(5%);
  }

  .congrats {
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .starBox {
    width: 48vw;
    max-width: 20rem;
    position: relative;

    .star {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    text-align: center;
    margin-top: -5vw;
    font-family: "Teko", sans-serif;
    z-index: 300;

    @include xs {
      margin-top: -20vw;
    }

    h1 {
      -webkit-text-stroke: 3px black;
      &:last-of-type {
        font-size: 5.6rem;
        line-height: 4rem;
        -webkit-text-stroke: 3.6px black;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
    gap: 3rem;

    @include md {
      justify-content: space-between;
      gap: 1.6rem;
    }

    .linkBox {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;
      max-width: 45rem;

      img {
        width: 100%;
        height: 100%;
      }

      a {
        width: 100%;
        position: absolute;
        padding-right: 2rem;

        h3 {
          color: rgb(126, 126, 126);
          padding: 0 2rem;
          padding-bottom: 0.4rem;
          margin-right: 0.3rem;
          font-weight: 600;
          overflow-x: scroll;
          font-family: "Teko", sans-serif;
        }
      }
    }

    .share {
      height: 100%;
      cursor: pointer;
      z-index: 2;
    }
  }

  .box {
    width: 100%;
    height: 50dvh;
    min-height: 40rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
    border: 0.3rem solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s;

    .inner {
      width: 100%;
      height: calc(100% - 1rem);
      background-color: #fff;
      border-radius: 1.8rem 1.8rem 2.3rem 2.3rem;
      z-index: 2;
      padding: 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        color: #000;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 2rem 0;

        img {
          width: 7rem;
          height: 7rem;
          object-fit: contain;
        }

        .innerRow {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;

          div {
            background-color: #e5e5e5;
            height: 0.4rem;
            width: 1.2rem;
          }

          input {
            font-size: 3rem;
            width: 6.5rem;
            height: 7rem;
            text-align: center;
            border-radius: 1.2rem;
            border: 2px solid #e5e5e5;

            transition: all 0.3s;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            -moz-appearance: textfield;
            appearance: textfield;

            &::placeholder {
              color: #c6c6c6;
            }

            &:disabled {
              background-color: #f5f5f5;
              color: black;
            }

            &:focus {
              outline: none !important;
              border: 2px solid #c6c6c6;
            }
          }
        }
      }

      .input {
        font-size: 2.8rem;
        width: 100%;
        height: 8rem;
        padding: 0 3rem;
        border-radius: 1.2rem;
        border: 2px solid #e5e5e5;
        font-weight: 400;
        transition: all 0.3s;

        &::placeholder {
          color: #c6c6c6;
        }

        &:focus {
          outline: none !important;
          border: 2px solid #c6c6c6;
        }
      }

      .error {
        color: darkred;
        margin-top: 2rem;
      }

      .button {
        display: grid;
        place-items: center;
        position: relative;
        width: 50%;
        align-self: center;
        @include xs {
          width: 100%;
        }
        margin-top: 2rem;

        cursor: pointer;

        h3 {
          position: absolute;
          padding-bottom: 0.6rem;
          color: white;
          font-weight: bold;
        }
      }

      .submittedBox {
        background-color: #a3d82653;
        width: 100%;
        border-radius: 1rem;
        border: 1px dashed #4e8000;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem;

        svg {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .finalButton {
    height: 10rem;
    width: 50%;

    margin: 6rem 0;

    @include xl {
      width: 100%;
    }

    @include xs {
      margin: 4rem 0;
    }

    @include xxs {
      margin: 2rem 0;
    }

    display: grid;
    place-items: center;
    text-align: center;
    position: relative;

    div {
      position: absolute;
      padding-bottom: 1rem;
      h4,
      h3 {
        color: black;
        font-weight: bold;
      }
    }
  }

  .loading {
    display: grid;
    place-items: center;
  }
}
